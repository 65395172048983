import * as React from 'react';
import Icon from '../../../icon';

class TagsInputItem extends React.Component {
  constructor() {
    super();

    this.handleRemove = this.handleRemove.bind(this);
  }

  static props;

  handleRemove() {
    const { item, onRemove } = this.props;
    onRemove(item);
  }

  render() {
    const { item } = this.props;
    return (
      <div className="Form__TagsInput__Tags__Item">
        {item}
        <Icon type="close" onClick={this.handleRemove} />
      </div>
    );
  }
}

export default TagsInputItem;
