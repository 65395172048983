import * as R from 'ramda';
import * as usersReducer from '../../core/reducers/users';
import * as networksReducer from '../../core/reducers/networks';
import * as teamsReducer from '../../network/reducers/teams';
import * as reducerUtil from '../../../common/utils/reducer';
import { FileType } from '../../../common/utils/file';
import * as channelSelector from '../../organisation/selectors/channel';
import * as eventsReducer from '../../events/reducer';
import * as commentsReducer from './comments';
import * as attachmentsReducer from './attachments';
import * as likesReducer from './likes';
import * as pollsReducer from './polls';
import {
  ORGANISATION_RECEIVE_MESSAGES,
  ORGANISATION_RECEIVE_MESSAGE,
  ORGANISATION_CREATE_MESSAGE,
  ORGANISATION_UPDATE_MESSAGE,
} from '../../admin/actions';
import {
  SOCIAL_RECEIVE_FEED,
  SOCIAL_RECEIVE_SCHEDULED_MESSAGES,
  SOCIAL_RECEIVE_IMPORTANT_MESSAGES,
  SOCIAL_RECEIVE_UNREAD_MESSAGES,
  SOCIAL_POST_MESSAGE,
  SOCIAL_MESSAGE_HIGH_FIVE,
  SOCIAL_MESSAGE_UNHIGH_FIVE,
  SOCIAL_POST_COMMENT,
  SOCIAL_EDIT_MESSAGE,
  SOCIAL_TRANSLATE_MESSAGE,
  SOCIAL_UPDATE_MESSAGE,
  SOCIAL_DELETE_MESSAGE,
  SOCIAL_RECEIVE_MESSAGE,
  SOCIAL_VOTE_POLL,
  SOCIAL_DELETE_COMMENT,
  SOCIAL_TOGGLE_MESSAGE_IMPORTANT,
  SOCIAL_TOGGLE_MESSAGE_COMMENTS,
  SOCIAL_MARK_AS_READ,
} from '../actions';
import { EObjectTypes } from '../definitions';
import { EParentTypes } from '../../../common/definitions';

const isMessage = R.either(
  R.propEq('object_type', EObjectTypes.MESSAGE),
  R.propEq('object_type', EObjectTypes.ORGANISATION_MESSAGE),
);

const addMessageToStore = (acc, object) => R.assoc(object.source.id, {
  ...object.source,
  actions: object.actions,
  seen_count: object.seen_count,
  created_in: { type: object.parent_type, id: object.parent_id },
  publish_at: object.publish_at,
  processed_at: object.processed_at,
}, acc);

export default (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
    case SOCIAL_RECEIVE_UNREAD_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGES:
      return R.pipe(R.filter(isMessage), R.reduce(addMessageToStore, state))(action.messages || action.items);
    case SOCIAL_EDIT_MESSAGE:
    case SOCIAL_POST_MESSAGE:
    case SOCIAL_RECEIVE_MESSAGE:
    case ORGANISATION_RECEIVE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
    case ORGANISATION_UPDATE_MESSAGE:
    case SOCIAL_TOGGLE_MESSAGE_IMPORTANT:
    case SOCIAL_TOGGLE_MESSAGE_COMMENTS:
      if (!action.message) return state;

      return addMessageToStore(state, action.message);
    case SOCIAL_TRANSLATE_MESSAGE:
      return reducerUtil.update(action.messageId, (message) => R.merge(message, {
        translated_text: R.assoc(action.locale, action.translated_text, message.translated_text || {}),
      }), state);
    case SOCIAL_POST_COMMENT:
      return reducerUtil.update(action.messageId, (message) => R.merge(message, {
        comments_count: message.comments_count + 1,
      }), state);
    case SOCIAL_DELETE_COMMENT:
      return reducerUtil.update(action.messageId, R.clone, state);
    case SOCIAL_MESSAGE_HIGH_FIVE:
      return reducerUtil.update(action.messageId, (message) => R.merge(message, {
        has_liked: true,
        likes_count: message.likes_count + 1,
      }), state);
    case SOCIAL_MESSAGE_UNHIGH_FIVE:
      return reducerUtil.update(action.messageId, (message) => R.merge(message, {
        has_liked: false,
        likes_count: message.likes_count - 1,
      }), state);
    case SOCIAL_MARK_AS_READ:
      return reducerUtil.update(action.messageId, (message) => R.merge(message, {
        has_read: true,
      }), state);
    case SOCIAL_UPDATE_MESSAGE:
      return reducerUtil.update(action.messageId, (message) => R.merge(message, action.props), state);
    case SOCIAL_VOTE_POLL:
      // Re-render the message after voting on poll
      return {
        ...state,
        [action.messageId]: { ...state[action.messageId] },
      };
    case SOCIAL_DELETE_MESSAGE:
      return R.omit([action.messageId], state);
    default: return state;
  }
};

const getName = (object) => (object ? object.name : '"Verwijderd"');

const getParent = (state, { created_in: createdIn }) => R.cond([
  [R.equals(EParentTypes.NETWORK),
    () => networksReducer.findById(state, createdIn.id)],
  [R.equals(EParentTypes.TEAM),
    () => teamsReducer.findById(state, createdIn.id)],
  [R.equals(EParentTypes.ORGANISATION),
    () => state.organisation.selected],
  [R.equals(EParentTypes.CHANNEL),
    () => channelSelector.item(state, createdIn.id)],
  [R.equals(EParentTypes.EVENT),
    () => eventsReducer.findById(state, createdIn.id)],
  [R.T, R.always('')],
])(createdIn.type);

export const transformObject = (message, {
  comments = [],
  likes = [],
  attachments = [],
  seen = true,
  user,
  createdIn,
  poll,
}) => {
  const images = R.filter(R.propEq('file_type', FileType.IMAGE), attachments);
  const videos = R.filter(R.propEq('file_type', FileType.VIDEO), attachments);
  const files = R.filter(R.complement(R.either(R.propEq('file_type', FileType.IMAGE), R.propEq('file_type', FileType.VIDEO))), attachments);

  return {
    ...message,
    comments,
    likes,
    attachments: images,
    videos,
    files,
    seen,
    user,
    poll,
    created_in: createdIn,
  };
};

export const findById = R.curry((state, id) => {
  const message = state.social.messages[id];

  if (!message) return undefined;

  const comments = commentsReducer.findByMessage(state, id);
  const attachments = attachmentsReducer.findByMessage(state, id);
  const likes = likesReducer.findByMessage(state, id);
  const parent = getParent(state, message);

  return transformObject(message, {
    comments,
    likes,
    attachments,
    seen: !!state.social.seen.objects[message.object_id],
    user: usersReducer.findById(state, message.created_by),
    createdIn: {
      ...message.created_in,
      parent,
      values: getName(parent),
    },
    poll: pollsReducer.findByMessageId(state, message.id),
  });
});
