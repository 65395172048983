import React from 'react';
import { omit } from 'lodash';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

import { PollInput, PollInputOwnProps } from './poll-input';

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export function PollInputHookForm<T extends FieldValues>(
  props: Omit<PollInputOwnProps, 'value' | 'onChange'> & UseControllerProps<T>,
) {
  const { field } = useController(props);
  return (
    <PollInput
      {...props}
      {...field}
    />
  );
}

const ReduxFormField = ({ input, ...props }: WrappedFieldProps & PollInputOwnProps) => {
  return (
    <PollInput
      {...input}
      {...omit(props, ['meta', 'input'])}
    />
  );
};

export default ({ name, ...props }: BaseFieldProps & PollInputOwnProps) => (
  <Field
    name={name}
    component={ReduxFormField}
    props={props}
  />
);
