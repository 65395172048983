import { useAppSelector } from '@hooks/redux';
import { EPermissions, Permissions } from '@common/definitions';
import * as userSelector from '../../modules/core/selectors/logged-user';
import { hasEnabledComponent } from '@common/components/permission';
import * as organisationsSelector from '@modules/organisation/selectors/organisation';
import * as networkSelector from '@modules/network/selectors/network';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useComponentEnabledChecker = (requiredComponent: string) => {
  const org = useSelector(organisationsSelector.selected);
  const network = useSelector(networkSelector.selected);
  return hasEnabledComponent(requiredComponent, network, org);
};

export const useUserPermissions = () => {
  const permissions = useAppSelector(userSelector.permissions);

  const hasPermissions = (
    shouldHave: typeof EPermissions[keyof typeof EPermissions][],
    some?: boolean,
  ) => shouldHave[some ? 'some' : 'every'](
    (perm) => permissions.includes(perm),
  );

  return {
    permissions,
    hasPermissions,
  };
};

export const usePermission = (permissions: Permissions | Permissions[]) => {
  const toCheck = useMemo(() => {
    return Array.isArray(permissions) ? permissions : [permissions];
  }, [permissions]);

  const userPermissions = useAppSelector(userSelector.permissions) as Permissions[];

  return useMemo(() => {
    return toCheck.some((permission) => userPermissions.includes(permission));
  }, [userPermissions, toCheck]);
};
