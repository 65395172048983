import React from 'react';

type Props = {
  status: number;
  processing?: boolean;
  error?: boolean;
  size?: 'large';
  showPercentage?: boolean;
};

export const ProgressBar = React.memo<Props>(({
  status,
  processing,
  error,
  size,
  showPercentage,
}: Props) => {
  const className = ['ProgressBar__Status'];
  if (error) className.push('ProgressBar__Status--error');
  if (!error && processing) className.push('ProgressBar__Status--processing');
  if (size) className.push(`ProgressBar--size-${size}`);

  return (
    <div className="ProgressBar__Container">
      <div className="ProgressBar">
        <div className={className.join(' ')} style={{ width: `${status}%` }} />
      </div>
      {showPercentage && (
      <span>
        {status}
        %
      </span>
      )}
    </div>
  );
});
