import React, { KeyboardEvent } from 'react';

import { Icon } from '@common/components/icon';

export type TOption = {
  id: string;
  value: string;
};

type FormInputOptionsOptionOwnProps = {
  item: TOption;
  index: number;
  onAddNewOption: () => void;
  onChange: (index: number, option: TOption['value']) => void;
  onRemove: (idToRemove: string) => void;
};

const FormInputOptionsOption = ({
  item, index, onAddNewOption, onChange, onRemove,
}: FormInputOptionsOptionOwnProps) => {
  return (
    <div className="Form__Poll__Options__Option">
      <Icon type="remove_circle_outline" onClick={() => onRemove(item.id)} />
      <input
        defaultValue={item.value}
        onChange={(e) => onChange(index, e.target.value)}
        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            onAddNewOption();
          }
        }}
        maxLength={250}
      />
      <small>
        {item.value.length}
        /250
      </small>
    </div>
  );
};

export default FormInputOptionsOption;
