import * as R from 'ramda';

import { EComponentTypes } from '@modules/learning/definitions';
import { FormDetail } from '@modules/forms/types';
import { EFilters } from '@modules/forms/constants';
import type { Screen } from '../learning/types/objects';

export const transformScreenFromAPI = ((screen: Screen) => ({
  ...screen,
  component_ids: screen.components.map((c) => c.id),
}));

const getFormattedParams = (type: string, params: Record<string, any>) => {
  switch (type) {
    case EComponentTypes.TEXT:
      return {
        text: params.text,
      };
    case EComponentTypes.IMAGE:
    case EComponentTypes.PDF:
    case EComponentTypes.VIDEO:
    case EComponentTypes.EXTERNAL_VIDEO:
      return {
        attachmentId: params.attachmentId,
        attachment: params.attachment,
      };
    case EComponentTypes.QUESTION_TEXT:
      return {
        text: params.text,
        description: params.description,
        answer_required: params.answer_required || false,
      };
    case EComponentTypes.QUESTION_DATE_TIME:
      return {
        text: params.text,
        description: params.description,
        answer_required: params.answer_required || false,
        allow_date_select: params.allow_date_select || false,
        allow_time_select: params.allow_time_select || false,
      };
    case EComponentTypes.QUESTION_FILE_UPLOAD:
      return {
        text: params.text,
        description: params.description,
        answer_required: params.answer_required || false,
        allow_multiple_uploads: params.allow_multiple_uploads || false,
      };
    case EComponentTypes.QUESTION_DROPDOWN:
      return {
        text: params.text,
        description: params.description,
        answer_required: params.answer_required || false,
        allow_multiple_options: params.allow_multiple_options || false,
        options: params.options,
      };
    case EComponentTypes.QUESTION_SLIDER:
      return {
        text: params.text,
        description: params.description,
        answer_required: params.answer_required || false,
        amount_of_steps: params.amount_of_steps,
        label_left: params.label_left,
        label_center: params.label_center,
        label_right: params.label_right,
      };
    case EComponentTypes.QUESTION_CHECKLIST:
      return {
        text: params.text,
        description: params.description,
        answer_required: params.answer_required || false,
        allow_multiple_options: params.allow_multiple_options || false,
        options: params.options,
      };
    case EComponentTypes.QUESTION_NUMBER:
      return {
        text: params.text,
        description: params.description,
        answer_required: params.answer_required || false,
        allow_decimals: params.allow_decimals || false,
      };
    case EComponentTypes.QUESTION_BOOLEAN:
      return {
        text: params.text,
        description: params.description,
        answer_required: params.answer_required || false,
        label_true: params.label_true,
        label_false: params.label_false,
      };
    case EComponentTypes.QUESTION_MULTIPLE_CHOICE:
      return {
        text: params.text,
        description: params.description,
        answer_required: params.answer_required || false,
        allow_multiple_options: params.allow_multiple_options || false,
        options: params.options,
      };
    default:
      return params;
  }
};

// beware that this function is used for both surveys and forms, so the screen
// can be a survey screen or a
export const screenToPayload = (screen: Screen, form: FormDetail) => {
  const components = screen.components.map(R.omit(['id', 'local']));

  const isPublished = form?.status === EFilters.LIVE;

  return {
    isPublished,
    // this mapping fixes PD-8759
    components: components.map((component: Record<string, any>) => ({
      ...component,
      parameters: getFormattedParams(component.type, component.parameters),
    })),
  };
};
