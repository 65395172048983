import { EDisableActivities } from '@common/definitions';

export const RECEIVE_NOTIFICATION = 'core/RECEIVE_NOTIFICATION';

export default (activity) => (dispatch, getState) => {
  if (
    EDisableActivities.includes(activity?.activity_type) ||
    !activity?.platform?.is_any_web
  ) return;

  const { organisation: { selected } } = getState();

  if (activity.organisation_id === selected) {
    dispatch({
      type: RECEIVE_NOTIFICATION,
      item: activity,
    });
  }
};
