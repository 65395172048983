import React from 'react';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { omit } from 'lodash';

import { TextInput, TextInputOwnProps } from './text-input';
import Label from '../../label';

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export function TextInputHookForm<T extends FieldValues>(
  props: Omit<TextInputOwnProps, 'ref'> & UseControllerProps<T> & { label?: string },
) {
  const { field, fieldState } = useController(props);
  return (
    <>
      {props.label && <Label
        minLength={props.minLength}
        text={props.label}
        input={{ value: field.value }}
      />}
      <TextInput
        {...props}
        {...field}
        onChange={props.onChange || field.onChange}
        error={fieldState.error?.message || fieldState.error?.message === ''}
      />
    </>
  );
}

const ReduxFormField = React.forwardRef<HTMLInputElement | undefined, WrappedFieldProps & TextInputOwnProps>(
  (props, ref) => (
    <TextInput
      ref={ref}
      key={props.input.name}
      error={props.meta.touched && props.meta.error}
      warning={props.meta.touched && props.meta.warning}
      {...props.input}
      {...omit(props, ['input', 'meta'])}
    />
  )
);

const TextInputField = React.forwardRef<HTMLInputElement | undefined, BaseFieldProps & TextInputOwnProps>(
  ({ name, ...props }, ref) => (
    <Field
      id={name}
      name={name}
      component={ReduxFormField}
      props={{
        ref,
        ...props
      }}
    />
  )
);

export default TextInputField;
