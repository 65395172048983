import * as R from 'ramda';
import moment from 'moment';
import { EExchangeTypes } from '../definitions';

export const EExchangeStatus = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  OPEN: 'OPEN',
};

export const isExpired = (exchange) => moment(exchange.date).diff(moment(), 'days') < 0;
export const hasStatus = R.curry((exchange, expected) => exchange.response_status === expected);
export const isApproved = (exchange) => exchange.is_approved;
export const isOpen = (r) => r.response && r.is_approved === null;

export const getExchangeStatus = (exchange) => {
  const checkStatus = hasStatus(exchange);

  if (isApproved(exchange)) return EExchangeStatus.APPROVED;
  if (isExpired(exchange)) return EExchangeStatus.EXPIRED;
  if (checkStatus(EExchangeStatus.REJECTED)) return EExchangeStatus.REJECTED;
  if (checkStatus(EExchangeStatus.ACCEPTED)) return EExchangeStatus.ACCEPTED;
  if (checkStatus(EExchangeStatus.DECLINED)) return EExchangeStatus.DECLINED;

  return EExchangeStatus.OPEN;
};

export const convertTimeToMoment = (time, date) => {
  if (!time) return null;

  const object = moment(time, ['YYYY-MM-DDTHH:mm:ssZ', 'HH:mm']);

  if (object.isValid()) {
    if (date) {
      return date.clone()
        .hour(object.hour())
        .minute(object.minute())
        .seconds(0);
    }

    return object;
  }

  const [hour, minute] = time.split(':');

  return moment()
    .hour(hour)
    .minute(minute)
    .seconds(0);
};

export const transfromExchange = (exchange) => {
  const source = R.omit(['user'], { ...exchange });
  if (exchange.user) source.user_id = exchange.user.id;

  const object = { object_type: 'exchange', source_id: source.id, source };

  return object;
};

const getNames = (store, ids) => R.pipe(
  R.map((id) => store[id]),
  R.reject(R.isNil),
  R.pluck('name'),
)(ids);

export const getCreatedInValues = (state, createdIn) => R.cond([
  [R.equals(EExchangeTypes.ALL),
    () => [state.networks.items[createdIn.id].name]],
  [R.equals(EExchangeTypes.TEAM),
    () => getNames(state.network.teams.items, createdIn.ids)],
  [R.equals(EExchangeTypes.FUNCTION),
    () => getNames(state.organisation.functions.items, createdIn.ids)],
  [R.T, R.always('')],
])(createdIn.type);
