import React from 'react';
import { toastr } from 'react-redux-toastr';
import i18n from '../../i18n';
import Icon, { IconTypes } from '../components/icon';

// Pass empty string as message because they don't support elements as message
// so we put the message directly in the component renderer
const createAlert = (
  type: 'success' | 'warning' | 'error',
  title: string,
  icon: string,
) => (message: string | Error | JSX.Element) => toastr[type](title, '', {
  icon: <Icon type={icon as IconTypes} />,
  // @ts-expect-error
  component: () => (
    <div>
      {message instanceof Error
        ? message.message
        : message}
    </div>
  ),
});

export const success = createAlert('success', i18n.t('common:alert_title_success'), 'check');
export const warning = createAlert('warning', i18n.t('common:alert_warning_success'), 'warning');
export const error = createAlert('error', i18n.t('common:alert_error_success'), 'warning');

type StatusStrings = {
  success?: string,
  warning?: string,
  error?: string,
  [key: number]: string,
};

export const forStatus = (status: number, strings: StatusStrings) => {
  // Error occured but not on network level, so treat it as an error
  if (!status && strings.error) error(strings.error);

  // Overwrites any of the main status codes
  if (strings[status]) {
    warning(strings[status]);

    return true;
  }

  if (strings.success && status >= 200 && status <= 299) success(strings.success);
  if (strings.warning && status >= 400 && status <= 499) warning(strings.warning);
  if (strings.error && status >= 500) error(strings.error);

  return true;
};

export const AlertService = { success, warning, error, forStatus };
