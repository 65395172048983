import React from 'react';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';

import Tooltip from '../tooltip';
import Icon from '../icon';

export type Props = {
  text: any;
  input?: { value: any; };
  description?: string;
  forInput?: string;
  maxLength?: number;
  minLength?: number;
  required?: boolean;
};

const FormLabel = ({
  input, text, description, forInput, maxLength, minLength, required,
}: Props) => {
  const { t } = useTranslation();
  return (
    <label className="Form__Label" htmlFor={forInput}>
      {text}
      {required ? '*' : null}
      {minLength && input && input.value.length < minLength
        ? ` (${t('common:form_label_min_characters', { minLength })})`
        : maxLength && input && ` (${t('common:form_label_max_characters', { length: input.value.length, maxLength })})`}
      {description && <Tooltip title={description}><Icon type="info" /></Tooltip>}
    </label>
  );
};

export default (props: Props) => (props.forInput
  ? <Field name={props.forInput} {...props} component={FormLabel} />
  : <FormLabel {...props} />
);
