import * as R from 'ramda';
import { createSelector } from 'reselect';
import memoize from '@common/utils/memoize';
import _ from 'lodash';
import { emojiIndex } from 'emoji-mart';
import * as usersReducer from '../reducers/users';
import type { LooseObject } from '@common/types/util-types';
import type { Network } from '@common/types/objects';
import type { StoreState, Permissions } from '@common/types/store';
import { ECompanyDashboardPermissions, EComponentTypes } from '@common/definitions';
import * as orgSelectors from '@modules/organisation/selectors/organisation';
import * as networkSelectors from '@modules/network/selectors/network';
import { hasEnabledComponent } from '@common/utils/has-enabled-component';

const addUser = R.curry((state, object) => R.assoc(
  'user',
  usersReducer.findById(state, object.user_id),
  object,
));

const sortByName = R.sortBy(R.prop('name'));

const getLoggedUser = (state: StoreState) => state.loggedUser.user;
export const getOrganisationMembership = (state: StoreState) => state.loggedUser.memberships.organisation;
const getPermissions = (state: StoreState) => state.permissions;
const getNotifications = (state: StoreState) => state.activities;

// Currently logged in user
export const selected = createSelector(
  [getLoggedUser, getOrganisationMembership],
  (item, organisationMembership) => item && ({
    ...item,
    settings: organisationMembership,
  }),
);

// Currently selected network
const getSelectedNetwork = (state: StoreState) => state.networks.items[state.network.selected];
const getSelectedNetworkMembership = (state: StoreState) => state.loggedUser.memberships.networks[state.network.selected];

export const network = createSelector(
  [getSelectedNetwork, getSelectedNetworkMembership],
  (item, membership) => item && ({
    ...item,
    membership,
  }),
);

// Currently selected organisation
const getSelectedOrganisation = (state: StoreState) => state.organisation.selected;
export const getSelectedOrganisationMembership = (state: StoreState) => (
  // @ts-ignore
  state.loggedUser.memberships.organisations[state.organisation.selected.id]
);

export const organisation = createSelector(
  [getSelectedOrganisation, getSelectedOrganisationMembership],
  (item, membership) => item && ({
    ...item,
    membership,
  }),
);

const mergeMemberships = <Entity extends LooseObject<{ id: string }>, Membership>(
  items: Entity[],
  memberships: { [key: string]: Membership },
) => items.map((item) => ({ ...item, membership: memberships[item.id] }));

// All networks for the user
const getNetworks = (state: StoreState) => state.networks.items;
const getNetworkMemberships = (state: StoreState) => state.loggedUser.memberships.networks;

export const networks = createSelector(
  [getNetworks, getNetworkMemberships],
  (items, memberships) => sortByName(mergeMemberships(Object.values(items), memberships)),
);

// Get all permissions for the user
export const permissions = createSelector(
  // @ts-ignore
  [getPermissions, getSelectedNetwork, getSelectedOrganisation],
  (items: Permissions, selectedNetwork: Network): Array<string> => [
    ...items.organisation,
    ...((selectedNetwork && items.networks[selectedNetwork.id]) || []),
  ],
);

export const hasAnyOfPemissions = createSelector(
  [
    permissions,
    (state: StoreState, permissionNames: string[]) => permissionNames
  ],
  (perms: string[], permissionNames: string[]) => {
    return _.intersection(perms, permissionNames).length > 0;
  }
);

export const isAdmin = createSelector(
  [permissions],
  (permissionList: any[]) => {
    const adminPermission = (perm: string) => permissionList.includes(perm);
    return ECompanyDashboardPermissions.some(adminPermission);
  }
);

// Get all teams for user
const getTeams = (state: StoreState) => state.network.teams.items;
const getTeamMemberships = (state: StoreState) => state.loggedUser.memberships.teams;

export const teams = createSelector(
  [getTeams, getTeamMemberships],
  (items, memberships) => sortByName(mergeMemberships(Object.values(items), memberships)),
);

// Get all functions for user
const getFunctions = (state: StoreState) => state.organisation.functions.items;
const getFunctionMemberships = (state: StoreState) => state.loggedUser.memberships.functions;
const getIsMemberPermission = (_2: any, p: string): string | boolean => p || false;

export const functions = createSelector(
  [getFunctions, getFunctionMemberships, permissions, getIsMemberPermission],
  (items, memberships, allPermissions, requiredPermission) => {
    const shouldShowAll = (typeof requiredPermission === 'boolean')
      ? requiredPermission
      : allPermissions?.includes(requiredPermission);

    const orgFunctions = sortByName(mergeMemberships(Object.values(items), memberships));

    if (shouldShowAll) return orgFunctions;

    return orgFunctions.filter((item) => item.membership);
  },
);

// Get all channels for user
const getChannels = (state: StoreState) => state.organisation.channels.items;
const getChannelMemberships = (state: StoreState) => state.loggedUser.memberships.channels;

export const channels = createSelector(
  [getChannels, getChannelMemberships],
  // @ts-ignore
  (items, memberships) => mergeMemberships(Object.values(items), memberships)
  // @ts-ignore
    .sort((a, b) => a.index - b.index),
);

export const notifications = memoize.createSelector(
  [getNotifications],
  (state: StoreState, items) => R.pipe(
    R.map(addUser(state)),
    // @ts-ignore
    R.reject(R.propEq('user', undefined)),
    // @ts-ignore
  )(items),
);

// @ts-ignore
const getAcceptedDocuments = (state: StoreState) => state.loggedUser.accepted;

export const documentsToAccept = createSelector(
  [getAcceptedDocuments],
  (documents) => {
    const items: string[] = [];

    if (!documents.accepted_privacy) items.push('privacy');

    return items;
  },
);

export const getUserStatus = (state: StoreState) => {
  return state?.loggedUser?.memberships?.organisation?.status;
};

// we need to compare emojis with this function because some emojis when
// compared with == or === do not result equal even though they are
// (eg. "🗓" and "📅")
const compareMethodName = ('codePointAt' in String.prototype) ?
  'codePointAt' : 'charCodeAt';
function sameCode(textA: string, textB: string) {
  const codeA = textA[compareMethodName](0);
  const codeB = textB[compareMethodName](0);
  return codeA === codeB;
}

function getCompleteEmoji(status: any, emojis: Record<string, any>): any {
  /* eslint-disable no-restricted-syntax */
  for (const key in emojis) {
    if (!Object.prototype.hasOwnProperty.call(emojis, key)) {
      /* eslint-disable no-continue */
      continue;
    }

    const emojiData = emojis[key];
    const isSkinTonesDictionary = !('native' in emojiData);
    if (isSkinTonesDictionary) {
      const values = Object.values(emojiData);
      const sameCodeNestedEmojis = values.filter((nestedEmojiData: any) => {
        return sameCode(status.emoji, nestedEmojiData.native);
      });
      // two equal emojis but with different skin colour have the same unicode
      // code but return false when compared with ===, so we look for strictly
      // equal emojis in order to get the data for the appropriate skin colour,
      // if we don't find a strictly equal emoji then we fallback to the first
      // emoji with the same code (equal())
      if (sameCodeNestedEmojis.length > 0) {
        const eq = (nestedEmoji: any) => {
          return status.emoji === nestedEmoji.native;
        };
        const strictlyEqualNestedEmoji = sameCodeNestedEmojis.find(eq);
        if (strictlyEqualNestedEmoji) {
          return strictlyEqualNestedEmoji;
        }
        return sameCodeNestedEmojis[0];
      }
    } else if (sameCode(status.emoji, emojiData.native)) {
      return emojiData;
    }
  }
  return null;
}

export const formatUserStatus = (status: any) => {
  if (!status) return null;

  const emoji = getCompleteEmoji(status, emojiIndex.emojis);
  // console.log("debug formatUserStatus emoji", emoji);
  if (!emoji) return null;

  return {
    ...status,
    emoji,
  };
};

export const getFormattedUserStatus = createSelector(
  [getUserStatus],
  formatUserStatus
);

export const isEmployeeOnboardingEnabled = createSelector(
  [orgSelectors.selected, networkSelectors.selected, getOrganisationMembership],
  (org, net, memberships) => {
    return (
      hasEnabledComponent(EComponentTypes.ONBOARDING, net, org) &&
      !!memberships?.onboarding
    );
  }
);
