import moment, { Moment } from 'moment';
import { TFunction } from 'i18next';

import { isInPast, isToday, isTomorrow, isWithinDays } from './time';
import { ELanguages } from '@common/types/objects';

export enum TPositionInTime {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  WITHIN_WEEK = 'within_week',
  PAST = 'past',
  THIS_YEAR = 'this_year',
  FUTURE = 'future',
}

export const getMinTimeValue = (date?: Moment | null) => {
  const now = moment();
  return date?.isSame(now, 'day') ?
    now.add(1, 'hour').minute(0).format('HH:mm') :
    undefined;
};

export const getPositionInTime = (date?: string) => {
  if (!date) return undefined;
  const expirationDate = new Date(date);
  switch (true) {
    case isInPast(expirationDate):
      return TPositionInTime.PAST;
    case isToday(expirationDate):
      return TPositionInTime.TODAY;
    case isTomorrow(expirationDate):
      return TPositionInTime.TOMORROW;
    case isWithinDays(expirationDate, 7):
      return TPositionInTime.WITHIN_WEEK;
    case expirationDate.getFullYear() === (new Date()).getFullYear():
      return TPositionInTime.THIS_YEAR;
    default:
      return TPositionInTime.FUTURE;
  }
};

export const getFullPositionInTimeLabel = (date: string, locale: ELanguages, t: TFunction) => {
  const expirationDate = new Date(date);
  const relativeDate = getPositionInTimeLabel(date, locale, t);
  return `
    ${(relativeDate && `${relativeDate} ${t('dates:at')} `) || ''}
    ${expirationDate.toLocaleTimeString(locale, { hour: 'numeric', minute: '2-digit' })}
  `;
};

export const getPositionInTimeLabel = (date: string, locale: ELanguages, t: TFunction) => {
  const expirationDate = new Date(date);
  const type = getPositionInTime(date);

  if (type === TPositionInTime.TODAY) {
    return t('dates:today').toLowerCase();
  }
  if (type === TPositionInTime.TOMORROW) {
    return t('dates:tomorrow').toLowerCase();
  }
  if (type === TPositionInTime.WITHIN_WEEK) {
    return expirationDate.toLocaleDateString(locale, { weekday: 'long' });
  }

  const dayAndMonth = `${
    expirationDate.toLocaleDateString(locale, { day: 'numeric' })
  } ${
    expirationDate.toLocaleDateString(locale, { month: 'long' })
  }`;

  if (type === TPositionInTime.THIS_YEAR) {
    return dayAndMonth;
  }
  if (type === TPositionInTime.FUTURE) {
    return `${dayAndMonth} ${expirationDate.getFullYear()}`;
  }
  return '';
};

export const dateAndTimeToDate = (time: string = '00:00', date?: Moment | null) => {
  const [startHour, startMinute] = time.split(':');
  return moment(date)
    .hour(parseInt(startHour) || 0)
    .minute(parseInt(startMinute) || 0)
    .seconds(0);
};

export const dateAndTimeToStr = (...args: Parameters<typeof dateAndTimeToDate>) => {
  return dateAndTimeToDate(...args).toISOString();
};

export const stringToTimePickerValue = (dateString?: string) => {
  return (dateString && moment(dateString).format('HH:mm')) || '';
};
