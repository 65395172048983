import React, { useCallback, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import createTempId from '@common/utils/create-temp-id';
import { TextareaInput } from '@common/components/form/inputs/textarea';
import PureList from '../../../list';
import Icon from '../../../icon';
import Option, { TOption } from './option';

export type PollInputOwnProps = {
  value: {
    question: string;
    options: TOption[];
  };
  closePoll?: () => void;
  onChange: (newValue: PollInputOwnProps['value']) => void;
};

export const PollInput = ({
  value, closePoll, onChange,
}: PollInputOwnProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const options = ref.current.childNodes;
      const input = options[options.length - 1].childNodes[1] as HTMLElement;
      if (input) {
        input.focus();
      }
    }
  }, [value.options.length, ref]);

  const handleRemoveOption = useCallback((idToRemove) => {
    onChange({
      ...value,
      options: [...value.options.filter(({ id }) => id !== idToRemove)],
    });
  }, [value, onChange]);

  const handleAddNewOption = useCallback(() => {
    onChange({
      ...value,
      options: [...value.options, {
        id: createTempId(),
        value: '',
      }],
    });
  }, [value, onChange]);

  const handleChangeOption = useCallback((indexToChange, text) => {
    onChange({
      ...value,
      options: [...value.options.map((item, index) => {
        return indexToChange === index ? { ...value.options[index], value: text } : item;
      })],
    });
  }, [value, onChange]);

  return (
    <div className="Form__Poll">
      <div className="Form__Poll__Close">
        <Icon type="close" onClick={closePoll} />
      </div>
      <div className="Form__Poll__Question">
        <label className="Form__Label">
          <Trans i18nKey="common:form_input_poll_question" />
          &nbsp;(
          <Trans
            i18nKey="common:form_label_max_characters"
            values={{ length: value.question.length, maxLength: 250 }}
          />
          )
        </label>
        <TextareaInput
          value={value.question}
          placeholder={t('common:form_input_poll_question_placeholder')}
          onChange={(question) => onChange({
            ...value,
            question,
          })}
          maxLength={250}
        />
      </div>

      <PureList
        containerRef={ref}
        containerClassName="Form__Poll__Options"
        items={value.options}
        renderRow={Option}
        rowProps={{
          onAddNewOption: handleAddNewOption,
          onChange: handleChangeOption,
          onRemove: handleRemoveOption,
        }}
      />

      <div className="Form__Poll__Add">
        <input
          value=""
          placeholder={t('common:form_input_poll_add_option')}
          onFocus={handleAddNewOption}
          onChange={handleAddNewOption}
        />
      </div>
    </div>
  );
};
