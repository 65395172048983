import * as React from 'react';
import ReactSelect, { ReactSelectProps, Async as AsyncReactSelect } from 'react-select';
import { useTranslation } from 'react-i18next';

import { combineClassNames } from '@utils/combineClassNames';
import Icon from '@common/components/icon';
import i18n from '../../../../../i18n';
import { Message } from '../../message';

type Option = Record<string, any>;

export type SelectOptionComponentProps<O extends {} = Option> = {
  children: React.ComponentType;
  option: O;
  isSelected: boolean;
  isDisabled: boolean;
  onSelect: (option: O) => void;
};

type SimplifiedValueComponentProps = {
  id: string;
  children: Array<string>;
};

type TValue = {
  label?: string | null | undefined;
  value?: string | boolean | number | Record<string, any> | null;
  [key: string]: any;
};

const createSimplifiedValueComponent = (values: TValue[]) => ({ id, children }: SimplifiedValueComponentProps) => {
  const segmentedId = id.split('-');
  const index = parseInt(segmentedId[segmentedId.length - 1], 10);
  if (index > 0) return null;
  return (
    <>
      {children}
      {values.length > 1 && ` + ${values.length - 1}`}
    </>
  );
};

export type SelectProps = Omit<ReactSelectProps, 'value' | 'options' | 'onChange'> & {
  value?: TValue | string | boolean | number | null;
  onChange: (newValue: any) => void;
  options?: TValue[];
  getOptions?: (value: string) => Promise<TValue[]>;
  OptionComponent?: React.ComponentType<SelectOptionComponentProps>;
  className?: string,
  warning?: boolean | string;
  error?: boolean | string;
  simplifyMultiValue?: boolean;
  loadingPlaceholder?: React.ReactNode;
  autoload?: boolean;
  cache?: boolean;
};

const createSelect = (SelectInputComponent: any, extraProps?: Partial<SelectProps>) => ({
  className,
  getOptions,
  OptionComponent,
  simplifyMultiValue,
  placeholder,
  searchable = false,
  filterOption,
  error,
  warning,
  ...props
}: SelectProps) => {
  const { t } = useTranslation();

  const fullClassName = combineClassNames(className, {
    'ReactSelect__MultiSimplified': simplifyMultiValue,
    'Form__control--invalid': !warning && !!error,
    'Form__control--warning': !!warning,
  });

  return (
    <>
      <SelectInputComponent
        className={fullClassName}
        loadOptions={getOptions && (async (value: string) => {
          const result = await getOptions(value);
          return { options: result };
        })}
          // Fixes react-select filtering options which have been returned by the getOptions
        filterOption={getOptions ? () => true : filterOption}
        closeOnSelect={!props.multi}
        searchable={searchable}
        optionComponent={OptionComponent}
        {...{ ...props, ...extraProps }}
        valueComponent={
          simplifyMultiValue && Array.isArray(props.value) ?
            createSimplifiedValueComponent(props.value) :
            undefined
        }
        noResultsText={t('common:select_no_results')}
        placeholder={(
          <>
            {searchable && <Icon type="search" />}
            {placeholder !== undefined
              ? placeholder
              : (searchable && t('common:select_placeholder_search')) || t('common:select_placeholder_select')}
          </>
          )}
      />
      <Message error={error} warning={warning} />
    </>
  );
};

export const Select = createSelect(ReactSelect);
const Async = createSelect(AsyncReactSelect, {
  searchable: true,
  autoload: false,
  cache: false,
  loadingPlaceholder: i18n.t('common:select_loading_placeholder'),
});

export const AsyncSelect = Async;

export default Select;
