import React from 'react';

import { combineClassNames } from '@utils/combineClassNames';

import * as Icons from '../../../../assets/icons';

export type IconTypes = keyof typeof Icons;
export const iconTypes = Object.keys(Icons);

export type IconProps = JSX.IntrinsicElements['svg'] & {
  type: IconTypes | undefined;
  color?: string;
  size?: 'xsmall' | 'small' | 'large' | number;
  iconRef?: React.MutableRefObject<SVGElement | undefined>;
  isFilled?: boolean;
};

const SIZES = {
  large: 30,
  small: 18,
  xsmall: 14
};

export const Icon = ({
  type,
  color,
  size,
  style = {},
  className,
  iconRef,
  isFilled,
  ...props
}: IconProps) => {
  if (!type) return null;

  const generalClassName = combineClassNames('Icon', className, {
    [`Icon--color-${color}`]: !!color,
  });

  const currentType = (isFilled ? `${type}__filled` : type) as IconTypes;

  const ChosenIcon = Icons?.[currentType];

  const allIconStyles = { ...style };

  if (typeof size === 'number') {
    allIconStyles.width = size;
    allIconStyles.height = size;
  } else if (typeof size === 'string' && typeof SIZES[size] === 'number') {
    allIconStyles.width = SIZES[size];
    allIconStyles.height = SIZES[size];
  }

  // @ts-ignore
  if (ChosenIcon?.$$typeof !== Symbol.for('react.forward_ref')) {
    // eslint-disable-next-line no-console
    console.error(`Specified icon (type='${type}') doesn't exist.`);
    return null;
  }

  return (
    <ChosenIcon
      {...props}
      className={generalClassName}
      style={allIconStyles}
      ref={iconRef}
    />
  );
};
