import { Network, Organisation } from '@common/types/objects';
import { EComponentTypes } from '@common/definitions';

type ComponentTypesKeys = keyof typeof EComponentTypes;
export type ComponentTypesValues = typeof EComponentTypes[ComponentTypesKeys];

export function hasEnabledComponent(
  requiredComponent: ComponentTypesValues,
  network: Network,
  organisation: Organisation
) {
  const enabledComponents = [
    ...((network && network.enabled_components) || []),
    ...((organisation && organisation.enabled_components) || [])
  ];

  return enabledComponents.some((component) => component === requiredComponent);
}
