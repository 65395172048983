import { EComponentTypes } from '../learning/definitions';

export const EQuestionTypes = <const>{
  CONTENT: 'content', // Not really a question
  TEXT: EComponentTypes.QUESTION_TEXT,
  MULTIPLE_CHOICE: EComponentTypes.QUESTION_MULTIPLE_CHOICE,
  SLIDER: EComponentTypes.QUESTION_SLIDER,
  DATE_TIME: EComponentTypes.QUESTION_DATE_TIME,
  NUMBER: EComponentTypes.QUESTION_NUMBER,
  DROPDOWN: EComponentTypes.QUESTION_DROPDOWN,
  BOOLEAN: EComponentTypes.QUESTION_BOOLEAN,
  FILE_UPLOAD: EComponentTypes.QUESTION_FILE_UPLOAD,
  CHECKLIST: EComponentTypes.QUESTION_CHECKLIST,
};

export const ESurveyTypes = <const>{
  DIRECT: 'direct',
  ONGOING: 'ongoing',
};

export const ESurveyStatus = <const>{
  DRAFT: 'draft',
  LIVE: 'live',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
  SCHEDULED: 'scheduled',
};

export enum EPulseSurveyStatusTypes {
  DRAFT = 'draft',
  LIVE = 'live',
  ARCHIVED = 'archived',
  PAUSED = 'paused',
}

export type EPulseSurveyStatusType = `${EPulseSurveyStatusTypes}`;

export const EPulseSurveyCategories = {
  AMBASSADORSHIP: 'ambassadorship',
  PERSONAL_GROWTH: 'personal_growth',
  RECOGNITION_AND_FEEDBACK: 'recognition_and_feedback',
  WELLNESS: 'wellness',
  ALIGNMENT: 'alignment',
  TEAMWORK: 'teamwork',
  HAPPINESS: 'happiness',
  RELATIONSHIP_WITH_MANAGER: 'relationship_with_manager',
  SATISFACTION: 'satisfaction',
  INCLUSION: 'inclusion'
} as const;

export enum EPulseSurveyResultTypes {
  ENGAGEMENT = 'engagement',
  PARTICIPATION = 'participation',
  ENPS = 'eNPS'
}
