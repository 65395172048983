import { combineReducers } from 'redux';
import * as R from 'ramda';
import * as reducerUtils from '../../../common/utils/reducer';
import {
  ORGANISATION_RECEIVE_MESSAGES,
  ORGANISATION_RECEIVE_MESSAGE,
  ORGANISATION_CREATE_MESSAGE,
  ORGANISATION_UPDATE_MESSAGE
} from '../../admin/actions';
import {
  SOCIAL_RECEIVE_FEED,
  SOCIAL_RECEIVE_SCHEDULED_MESSAGES,
  SOCIAL_RECEIVE_IMPORTANT_MESSAGES,
  SOCIAL_RECEIVE_UNREAD_MESSAGES,
  SOCIAL_POST_MESSAGE,
  SOCIAL_EDIT_MESSAGE,
  SOCIAL_RECEIVE_MESSAGE,
  SOCIAL_VOTE_POLL,
} from '../actions';

const isPoll = R.filter(R.propEq('object_type', 'poll'));
const addToStore = (acc, object) => R.assoc(object.source_id, {
  ...object.source,
  options: R.pluck('id', object.source.options),
  user_id: object.user_id,
}, acc);

const items = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
    case SOCIAL_RECEIVE_UNREAD_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGES:
      return R.pipe(
        R.pluck('children'),
        R.flatten,
        isPoll,
        R.reduce(addToStore, state),
      )(action.messages || action.items);
    case SOCIAL_RECEIVE_MESSAGE:
    case SOCIAL_POST_MESSAGE:
    case SOCIAL_EDIT_MESSAGE:
    case ORGANISATION_RECEIVE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
    case ORGANISATION_UPDATE_MESSAGE:
      if (!action.message) return state;

      return R.pipe(isPoll, R.reduce(addToStore, state))(action.message.children);
    case SOCIAL_VOTE_POLL:
      return reducerUtils.update(action.pollId, R.evolve({
        vote_result: R.always(action.optionIds),
        total_vote_count: !action.currentVoteResult ? R.inc : R.identity,
      }), state);
    default: return state;
  }
};

const addOptionToStore = (acc, option) => R.assoc(option.id, option, acc);

const options = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
    case SOCIAL_RECEIVE_UNREAD_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGES:
      return R.pipe(
        R.pluck('children'),
        R.flatten,
        isPoll,
        R.map(R.path(['source', 'options'])),
        R.flatten,
        R.reduce(addOptionToStore, state),
      )(action.messages || action.items);
    case SOCIAL_RECEIVE_MESSAGE:
    case SOCIAL_POST_MESSAGE:
    case SOCIAL_EDIT_MESSAGE:
    case ORGANISATION_RECEIVE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
    case ORGANISATION_UPDATE_MESSAGE:
      if (!action.message) return state;

      return R.pipe(
        isPoll,
        R.map(R.path(['source', 'options'])),
        R.flatten,
        R.reduce(addOptionToStore, state),
      )(action.message.children);
    case SOCIAL_VOTE_POLL: {
      const newState = action.optionIds.reduce((acc, optionId) => reducerUtils.update(optionId, R.evolve({ vote_count: R.inc }), acc), state);

      // Decrement vote count on currently selected options
      if (action.currentVoteResult) {
        return action.currentVoteResult.reduce((acc, optionId) => reducerUtils.update(optionId, R.evolve({ vote_count: R.dec }), acc), newState);
      }

      return newState;
    }
    default: return state;
  }
};

const addToMessage = (acc, object) => R.assoc(object.parent_id, object.source_id, acc);

const messages = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
    case SOCIAL_RECEIVE_UNREAD_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGES:
      return R.pipe(
        R.pluck('children'),
        R.flatten,
        isPoll,
        R.reduce(addToMessage, state),
      )(action.messages || action.items);
    case SOCIAL_POST_MESSAGE:
    case SOCIAL_RECEIVE_MESSAGE:
    case SOCIAL_EDIT_MESSAGE:
    case ORGANISATION_RECEIVE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
    case ORGANISATION_UPDATE_MESSAGE:
      if (!action.message) return state;

      return R.pipe(isPoll, R.reduce(addToMessage, state))(action.message.children);
    default: return state;
  }
};

const findOptionById = R.curry((state, id) => state.social.polls.options[id]);

export const findByMessageId = R.curry((state, messageId) => {
  const poll = state.social.polls.items[state.social.polls.messages[messageId]];

  if (!poll) return null;

  return {
    ...poll,
    options: R.map(findOptionById(state), poll.options || []),
  };
});

export default combineReducers({ items, options, messages });
