import React from 'react';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { omit } from 'lodash';
import { ColorInput, ColorInputOwnProps } from './color-input';

const ReduxFormField = (props: WrappedFieldProps & ColorInputOwnProps) => {
  return (
    <ColorInput
      input={props.input}
      {...omit(props, ['input', 'meta'])}
    />
  );
};

const ColorInputField = ({ name, ...props }: BaseFieldProps & ColorInputOwnProps) => (
  <Field
    id={name}
    name={name}
    component={ReduxFormField}
    props={props}
  />
);

export default ColorInputField;
