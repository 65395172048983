import React, { useCallback, useEffect, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

export type ColorInputOwnProps = {
  input: {
    value: string;
    onChange: (newColor: string) => void;
  },
  className?: string,
  defaultColor?: string,
  hideValue?: boolean;
};

export const ColorInput = ({
  input, defaultColor, hideValue,
}: ColorInputOwnProps) => {
  const [preview, setPreview] = useState(input.value || defaultColor);
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setPreview(input.value || defaultColor);
  }, [input.value, defaultColor, setPreview]);

  const onChange = useCallback((color: ColorResult) => {
    setPreview(color.hex);
    input.onChange(color.hex);
  }, [input, setPreview]);

  return (
    <div className="ColorInput">
      <div className="ColorInput__Input" onClick={() => setShowPicker(true)}>
        <div
          className="ColorInput__Input__Preview"
          style={{ backgroundColor: preview }}
        />
        {!hideValue && (<input
          disabled
          type="text"
          value={preview}
        />)}
      </div>
      {showPicker && (
        <>
          <div className="ColorInput__Backdrop" onClick={() => setShowPicker(false)} />
          <div className="ColorInput__Picker">
            <div className="ColorInput__Picker__Inner">
              <SketchPicker
                disableAlpha
                color={preview}
                presetColors={[]}
                onChange={onChange}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
