class PlacesApi {
  // @ts-expect-error
  private readonly maps: Promise<typeof google.maps>;

  constructor() {
    if (!window.document) return;

    this.maps = new Promise<typeof google.maps>((resolve) => {
      if (window.loadedPlacesApi) {
        return resolve(google.maps);
      }

      const placesScript = window.document.createElement('script');
      const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACES_API_KEY}&libraries=places`;
      placesScript.setAttribute('src', src);
      placesScript.setAttribute('async', '');
      placesScript.onload = () => {
        window.loadedPlacesApi = true;
        resolve(google.maps);
      };
      window.document.head.append(placesScript);
    });
  }

  getAutocomplete = async (input: string) => {
    const maps = await this.maps;

    return new Promise<google.maps.places.AutocompletePrediction[] | null>((resolve) => {
      const service = new maps.places.AutocompleteService();

      service.getPlacePredictions({ input }, (p) => resolve(p));
    });
  };

  getPlaceDetails = async (placeId: string, fields = ['geometry', 'formatted_address']) => {
    const maps = await this.maps;

    return new Promise<google.maps.places.PlaceResult | null>((resolve) => {
      const mapContainer = document.createElement('div');
      const map = new maps.Map(mapContainer);
      const service = new maps.places.PlacesService(map);

      const request = { placeId, fields };
      service.getDetails(request, (result) => resolve(result));
      mapContainer.remove();
    });
  };

  getPlaceLocation = async (placeId: string) => {
    const placeDetails = await this.getPlaceDetails(placeId);
    return {
      geometry: placeDetails?.geometry?.location?.toJSON?.(),
      address: placeDetails?.formatted_address,
    };
  };
}

export const placesApi = new PlacesApi();
