export const CHAT_ADD_MESSAGE = 'chat/ADD_MESSAGE';

export default (conversationId, item, read = true, conversation) => {
  return {
    type: CHAT_ADD_MESSAGE,
    conversationId,
    item,
    read,
    conversation,
  };
};
