import * as React from 'react';
import { Field } from 'redux-form';
import * as R from 'ramda';
import Icon from '../../../icon';
import PureList from '../../../list';
import TagItem from './item';

class TagsInput extends React.Component {
  constructor() {
    super();

    this.state = {
      disabled: true,
    };

    this.setReference = (ref) => (this.input = ref);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  static props;

  input;

  handleKeyPress(e) {
    if (e.charCode === 13 && !this.state.disabled) {
      const { value, onChange } = this.props.input;

      if (Array.isArray(value)) {
        onChange([...value, e.target.value]);
      } else {
        onChange([e.target.value]);
      }

      e.target.value = ''; // eslint-disable-line no-param-reassign
      this.setState({ disabled: true });
    }
  }

  handleChange(e) {
    const { value } = this.props.input;

    const disabled = value.some(R.equals(e.target.value.trim())) || e.target.value === '';

    if (disabled !== this.state.disabled) this.setState({ disabled });
  }

  handleAdd() {
    if (this.input) {
      const tag = this.input.value;
      const { value, onChange } = this.props.input;

      if (Array.isArray(value)) {
        onChange([...value, tag]);
      } else {
        onChange([tag]);
      }

      this.input.value = ''; // eslint-disable-line no-param-reassign
      this.setState({ disabled: true });
    }
  }

  handleRemove(tag) {
    const { value, onChange } = this.props.input;

    onChange(R.reject(R.equals(tag), value));
  }

  render() {
    const { disabled } = this.state;
    const { placeholder, input } = this.props;

    const tags = Array.isArray(input.value) ? input.value : [];

    return (
      <div>
        <div className="Form__control Form__TagsInput">
          <div
            className={`Form__TagsInput__Add${disabled ? ' Form__TagsInput__Add--disabled' : ''}`}
            onClick={!disabled && this.handleAdd}
          >
            <Icon type="add" size={20} />
          </div>

          <input
            ref={this.setReference}
            placeholder={placeholder}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
          />
        </div>

        {tags.length > 0 && (
          <div className="Form__TagsInput__Tags">
            <PureList
              containerClassName="Form__TagsInput__Tags"
              items={tags}
              renderRow={TagItem}
              rowProps={{
                onRemove: this.handleRemove,
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default (props) => <Field {...props} component={TagsInput} />;
