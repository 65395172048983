import React, { Key } from 'react';

import { useOnScrollBottom } from '@common/hooks';
import type { LooseObject } from '@common/types/util-types';

export type PureListProps<ItemType, RowProps> = {
  id?: string;
  className?: string;
  items?: ItemType[];
  renderRow: React.FunctionComponent<{ item: ItemType, index: number } & RowProps>;
  rowProps?: RowProps | ((item: ItemType, index: number) => Record<string, unknown>);
  containerClassName?: string;
  containerRef?: React.Ref<HTMLDivElement>;
  scrollContainer?: HTMLDivElement;
  placeholder?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onContainerScroll?: (e: React.UIEvent<HTMLDivElement, WheelEvent>) => void;
  onScrollBottom?: (() => Promise<any>) | undefined;
  onBottomTriggerOffset?: number;
};

export function PureList<ItemType extends LooseObject<{ id: string }>, RowProps extends Record<string, unknown>>({
  id,
  items,
  renderRow: Row,
  rowProps,
  containerClassName,
  containerRef = React.createRef(),
  scrollContainer,
  placeholder,
  header,
  footer,
  onScrollBottom,
  onBottomTriggerOffset,
}: PureListProps<ItemType, RowProps>) {
  useOnScrollBottom(scrollContainer, onScrollBottom, { onBottomTriggerOffset });

  const classNames = ['List'];
  if (containerClassName) classNames.push(containerClassName);

  if (!items || items.length === 0) {
    classNames.push('List--empty');

    return (
      <div className={classNames.join(' ')}>
        {placeholder}
      </div>
    );
  }

  return (
    <div id={id} className={classNames.join(' ')} ref={containerRef}>
      {header}
      {items.filter((item) => !!item).map((item, index) => {
        const itemProps = rowProps instanceof Function
          ? rowProps(item, index)
          : rowProps;

        const keyString = (itemProps?.key || 'id') as string;
        const key = (item[keyString] || item.value || index) as Key;

        // @ts-ignore
        return <Row {...itemProps} key={`list-row-${key}`} index={index} item={item} />;
      })}
      {footer}
    </div>
  );
}

export default PureList;
