import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { hasEnabledComponent } from '@common/utils/has-enabled-component';
import * as userSelector from '../../../modules/core/selectors/logged-user';
import * as organisationsSelector from '../../../modules/organisation/selectors/organisation';
import * as networkSelector from '../../../modules/network/selectors/network';

const removeNull = (check) => check !== null;

export class PermissionComponent extends React.Component {
  static defaultProps = {
    permissions: [],
    fallback: false,
  };

  constructor() {
    super();

    this.checkPermission = this.checkPermission.bind(this);
    this.hasComponent = this.hasComponent.bind(this);
    this.test = this.test.bind(this);
    this.and = this.and.bind(this);
  }

  static props;

  /**
   * Check if the required permissions are met.
   * @param {string} permission - The required permission
   * @param {boolean} check - Function checking for permission
   * @method shouldRender
   * @return {boolean} - Should render yes/no according to the checked permission
   */
  shouldRender(check, permission) {
    if (permission === undefined) return null;

    const hasPermission = Array.isArray(permission)
      ? permission.some(check)
      : check(permission);

    return { value: hasPermission, permission };
  }

  test() {
    const { test, permissions } = this.props;

    const value = test instanceof Function ? test(permissions) : test;

    return value === true;
  }

  and() {
    const { and, permissions } = this.props;

    const value = and instanceof Function ? and(permissions) : and;

    return value === true;
  }

  /**
   * Check if the required component is included in the network's enabled components.
   * @param {string} requiredComponent - The required component to check upon
   * @method hasComponent
   * @return {boolean} - The network has the required component enabled yes/no
   */
  hasComponent(requiredComponent) {
    const { organisation, network } = this.props;
    return hasEnabledComponent(requiredComponent, network, organisation);
  }

  checkPermission(name) {
    const { permissions } = this.props;

    return permissions.includes(name);
  }

  /**
   * Render method.
   * @method render
   * @return {object} Markup of the component
   */
  render() {
    const {
      children, name, component, test, and, fallback, log, deniedComponent,
    } = this.props;

    if (this.props.role || this.props.organisationRole) return children;

    if (!name && !component && test === undefined) {
      return null;
    }

    // One of these should be true
    const ors = [
      this.shouldRender(this.test, test !== undefined ? 'test' : undefined),
      this.shouldRender(this.checkPermission, name),
    ].filter(R.identity);

    // All of these must be true
    const ands = [
      this.shouldRender(this.hasComponent, component),
      this.shouldRender(this.and, and !== undefined ? 'and' : undefined),
    ].filter(R.identity);

    // Remove null values and check if they match the expectation
    const andCheck = !ands.some((check) => check && !check.value);
    const orCheck = ors.filter(removeNull).some((check) => check && !!check.value);

    if (log) console.warn('PERMISSION', ands, ors);

    // Check if any of the validations failed
    const testsFailed = (ands.length > 0 && !andCheck) || (ors.length > 0 && !orCheck);

    // Reverse output when providing the fallback property is defined
    if ((testsFailed && !fallback) || (!testsFailed && fallback)) return deniedComponent || null;

    return children;
  }
}

const mapStateToProps = (state) => ({
  permissions: userSelector.permissions(state),
  organisation: organisationsSelector.selected(state),
  network: networkSelector.selected(state),
});

export default connect(mapStateToProps)(PermissionComponent);
