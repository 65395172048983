import React, { ComponentProps } from 'react';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

import Select, { AsyncSelect } from './select-input';
import { omit } from 'lodash';

type ExcludedSelectProps = 'value' | 'onChange' | 'onFocus' | 'onBlur';
type BaseProps = Omit<ComponentProps<typeof Select | typeof AsyncSelect>, ExcludedSelectProps>;

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export function SelectInputHookForm<T extends FieldValues>(props: Omit<BaseProps, 'ref'> & UseControllerProps<T>) {
  const { field, fieldState } = useController(props);
  return (
    <Select
      {...props}
      {...omit(field, ['ref'])}
      onChange={(item: any) => {
        field.onChange(props.multi || !item ? item : item[props.valueKey || 'value']);
      }}
      error={fieldState.error?.message || fieldState.error?.message === ''}
    />
  );
}

const createSelect = (SelectInput: typeof Select | typeof AsyncSelect) => (props: WrappedFieldProps & BaseProps) => (
  <SelectInput
    {...omit(props, ['input', 'meta'])}
    {...props.input}
    warning={props.meta.touched && props.meta.warning}
    error={props.meta.touched && props.meta.error}
    // Bug within react-select when searchable is set to true
    // onBlur will be called for the search input, resetting the value on blur.
    // More info >> https://github.com/JedWatson/react-select/issues/805
    // Workaround that is suggested by the GitHub issue is used here.
    onBlur={() => props.input.onBlur(props.input.value)}
    onChange={(item: any) => {
      props.input.onChange(props.multi || !item ? item : item[props.valueKey || 'value']);
    }}
  />
);

const DefaultSelectInput = createSelect(Select);
const AsyncSelectInput = createSelect(AsyncSelect);

export const SelectInput = ({ getOptions, ...props }: BaseFieldProps & BaseProps) => (
  <Field
    {...props}
    getOptions={getOptions}
    component={getOptions ? AsyncSelectInput : DefaultSelectInput}
  />
);
