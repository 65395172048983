import addMessage from './add-message';

export const CHAT_RECEIVE_MESSAGE = 'chat/RECEIVE_MESSAGE';

export default ({ data: object, conversation }) => async (dispatch, getState) => {
  const {
    organisation: { selected },
    ui: { chat: chatUI },
    loggedUser: { user }
  } = getState();

  // Don't add messages from other organisations
  if (conversation.organisation_id !== selected.id) return;

  const isSelected = object.parent_id === chatUI.selectedConversationId;
  const isOpen = window.location.pathname.endsWith('/conversations');
  const isFocused = document.hasFocus ? document.hasFocus() : true;

  // the message can come from the same user if he/she used a different device to send it
  const fromSameUser = (typeof user?.id === 'string') && (user.id === object.user_id);

  const messageWasRead = fromSameUser || (isSelected && isOpen && isFocused);

  // Add message to chat
  dispatch(addMessage(
    object.parent_id,
    object,
    messageWasRead,
    conversation,
  ));
};
