import React, { useState, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { combineClassNames } from '@utils/combineClassNames';
import { Button } from '@common/components/button';
import Spinner from '@common/components/spinner';
import Alert from '@common/components/alert';
import { PureList } from './pure';
import type { LooseObject } from '@common/types/util-types';
import type { Error } from '@common/types/objects';
import type { LocalState, Request, AsyncDataProps } from '@common/components/list/types';
import type { PureListProps } from './pure';

let stateIndex = 0;

export function createState(cache: Partial<LocalState> = {}) {
  let state = R.merge({
    items: [],
    cursor: null,
    pagination: {
      limit: 0,
      offset: 0,
      total_count: 0,
    },
    nextCursor: null,
  }, cache);

  return {
    index: stateIndex++,
    get: () => state,
    set: (newState: Partial<LocalState>) => (state = { ...state, ...newState }),
  };
}

export type ShowMoreComponentProps = {
  onShowMore: () => Promise<void>,
  count: number,
  isFetching: boolean,
};

export type ShowNewComponentProps = {
  onShowNew: Function,
  count: number,
};

type ErrorComponentProps = {
  error: Error | unknown,
  onRetry: () => Promise<void>,
};

export type AsyncListProps<
ItemType,
RowProps,
APIType,
Filter,
> = PureListProps<ItemType, RowProps> & {
  data: AsyncDataProps<APIType, ItemType, Filter>,
  ShowMoreComponent?: React.FunctionComponent<ShowMoreComponentProps> | null,
  ShowNewComponent?: React.FunctionComponent<ShowNewComponentProps>,
  ErrorComponent?: React.FunctionComponent<ErrorComponentProps>,
  disableInitialFetch?: boolean,
  hideSpinner?: boolean,
};

const defaultShowMoreComponent = ({ onShowMore, isFetching }: ShowMoreComponentProps) => {
  const { t } = useTranslation();

  return (
    <Button onClick={onShowMore} isLoading={isFetching}>
      {t('common:async_list_load_more')}
    </Button>
  );
};

export function AsyncList<
  ItemType extends LooseObject<{ id: string }>,
  APIType extends LooseObject<{ id: string }>,
  RowProps extends Record<string, unknown>,
  Filter extends Record<string, unknown>,
>({
  data,
  items,
  header,
  hideSpinner,
  scrollContainer,
  containerClassName,
  rowProps,
  disableInitialFetch,
  ShowMoreComponent = defaultShowMoreComponent,
  ShowNewComponent,
  ErrorComponent,
  ...listProps
}: AsyncListProps<ItemType, RowProps, APIType, Filter>) {
  const { t } = useTranslation();
  const isFirstRender = React.useRef(true);

  const [isMounted, setIsMounted] = useState(true);
  const [isFetching, setIsFetching] = useState(!process.env.SERVER
    ? (items || data.cache?.items || []).length === 0
    : true);
  const [error, setError] = useState<Error | unknown | null>(null);

  const dataManager = useMemo(() => data.dataManager || createState(data.cache), [data.dataManager]);

  type APIRequest = Request<APIType>;

  const handleFetchData = async (
    offset: number = 0,
    newData?: AsyncDataProps<APIType, ItemType, Filter> | undefined,
    limit: number | undefined = data.limit,
    strategy?: ({
      items?: (state: LocalState, request: APIRequest) => LocalState['items'],
      pagination?: (state: LocalState, request: APIRequest) => LocalState['pagination'],
      cursor?: (state: LocalState, request: APIRequest) => LocalState['cursor'],
    }),
  ) => {
    const { nextCursor } = dataManager.get();

    const actualData = (newData || data);
    const {
      useCursor,
      dispatch,
      filter,
      cache,
      onPostFetch,
    } = actualData;

    try {
      setIsFetching(true);
      setError(null);

      dataManager.set({
        items: newData
          ? (cache && cache.items) || []
          : dataManager.get().items,
      });

      // const onFetch = data.useCursor ? data.onFetch : data.onFetch;

      const request = await (dispatch
        ? dispatch(useCursor
          ? actualData.onFetch(offset === 0 ? null : nextCursor, filter, limit, !strategy)
          : actualData.onFetch(offset, filter, limit, !strategy))
        : useCursor
          ? actualData.onFetch(offset === 0 ? null : nextCursor, filter, limit, !strategy)
          : actualData.onFetch(offset, filter, limit, !strategy)
      );

      if (isMounted) {
        if (!request) return setIsFetching(false);

        const localState = dataManager.get();

        dataManager.set({
          items: strategy?.items?.(localState, request)
            || request.data,
          pagination: !useCursor && request.meta?.pagination
            ? strategy?.pagination?.(localState, request)
              || request.meta.pagination
            // Fallback when there is no pagination
            : {
              total_count: request.data.length,
              offset: 0,
              limit: request.data.length,
            },
          nextCursor: strategy?.cursor?.(localState, request)
            || (request.meta && request.meta.pagination && request.meta.pagination.next_cursor),
        });

        setIsFetching(false);
        setError(null);
      }

      if (onPostFetch) onPostFetch(request, strategy ? 'append' : null, filter);
    } catch (err: unknown) {
      if (isMounted) {
        setIsFetching(false);
        setError(err);
      }
    }
  };

  // Fetch initial list (or not if disabled for initial render)
  useEffect(() => {
    if (!disableInitialFetch) handleFetchData(0);

    return () => setIsMounted(false);
  }, []);

  // Refetch items if filter is updated
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!isMounted) return;

    dataManager.set({ nextCursor: null });

    handleFetchData(0, data);
  }, data?.filter ? Object.values(data?.filter) : []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const { pagination, items: currentItems } = dataManager.get();

    if (!isMounted || !Array.isArray(items) || !pagination.offset) return;

    if (items.length > (pagination?.offset || 0) + (pagination?.limit || 0)) {
      dataManager.set({
        pagination: {
          ...pagination,
          offset: pagination.offset + (items.length - (currentItems?.length || 0)),
          total_count: (pagination.total_count || 0) + (items.length - (currentItems?.length || 0)),
        },
      });
    }
  }, [items?.length]);

  const handleFetchMoreData = () => {
    const { pagination: { offset, limit } } = dataManager.get();

    return handleFetchData((offset || 0) + (limit || 0), undefined, undefined, {
      items: (state, request) => [...state.items, ...request.data],
      pagination: (state, request) => request.meta.pagination,
    });
  };

  const handleFetchNewData = () => handleFetchData(0, undefined, data?.new, {
    items: (state, request) => [...request.data, ...state.items],
    pagination: (state, request) => ({
      ...state.pagination,
      offset: (state.pagination.offset || 0) + (request.meta.pagination.limit || 0),
    }),
    cursor: (state) => state.nextCursor,
  });

  const { items: currentItems, pagination, nextCursor } = dataManager.get();

  const headerElement = (typeof header === 'function') ? header({ pagination }) : header;
  const actualItems = (items || currentItems as ItemType[]).filter(data.filterFn || (() => true));

  if (error) {
    console.log('debug error', error);
    if (ErrorComponent) return <ErrorComponent error={error} onRetry={handleFetchData} />;

    return (
      <>
        <Alert type="error" title={t('common:async_list_error_while_fetching')} />
        <br />
        <br />
        <div className="Align Align--start">
          <Button type="primary" size="large" onClick={() => handleFetchData()}>
            {t('common:async_list_error_try_again')}
          </Button>
          {process.env.INTERCOM_APP_ID && (
            <Button type="inverted-primary" size="large" onClick={() => window?.Intercom?.('show')}>
              {t('common:async_list_error_contact_helpdesk')}
            </Button>
          )}
        </div>
      </>
    );
  }

  if ((!currentItems || currentItems.length === 0) && isFetching) {
    if (hideSpinner) return null;

    return (
      <div className="AsyncList">
        <div className={combineClassNames('List', containerClassName)}>
          {headerElement}
        </div>
        <Spinner centered size="large" />
      </div>
    );
  }

  const hasMoreItems = data.useCursor
    ? nextCursor || data.nextCursor
    : (pagination.limit || 0) + (pagination.offset || 0) < (pagination.total_count || 0) && ShowMoreComponent;

  let showMoreElement;

  if (hasMoreItems && !scrollContainer) {
    showMoreElement = (
      // @ts-expect-error
      <ShowMoreComponent
        onShowMore={handleFetchMoreData}
        count={(pagination.total_count || 0) - (pagination.limit || 0) - (pagination.offset || 0)}
        isFetching={isFetching}
      />
    );
  }

  let showNewElement;
  if (data && data.new && ShowNewComponent) {
    showNewElement = (
      <ShowNewComponent
        onShowNew={handleFetchNewData}
        count={data.new}
      />
    );
  }

  const newRowProps = typeof rowProps === 'function'
    ? (item: ItemType, index: number) => ({ ...rowProps(item, index), refetchItems: handleFetchData })
    : { ...rowProps, refetchItems: handleFetchData };

  return (
    <div className="AsyncList">
      {showNewElement}
      {/* @ts-expect-error */}
      <PureList
        {...listProps}
        items={actualItems}
        header={headerElement}
        scrollContainer={scrollContainer}
        containerClassName={containerClassName}
        onScrollBottom={hasMoreItems ? handleFetchMoreData : undefined}
        rowProps={newRowProps}
      />
      {showMoreElement}
    </div>
  );
}

export default AsyncList;
