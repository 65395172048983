export const EComponentTypes = <const>{
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  EXTERNAL_VIDEO: 'external_video',
  ELEARNING_QUESTION: 'question',
  PDF: 'pdf',
  QUESTION_TEXT: 'question_text',
  QUESTION_MULTIPLE_CHOICE: 'question_multiple_choice',
  QUESTION_SLIDER: 'question_slider',
  QUESTION_DATE_TIME: 'question_datetime',
  QUESTION_NUMBER: 'question_number',
  QUESTION_DROPDOWN: 'question_dropdown',
  QUESTION_BOOLEAN: 'question_boolean',
  QUESTION_FILE_UPLOAD: 'question_file_upload',
  QUESTION_CHECKLIST: 'question_checklist',
};

export enum EAcademySortableTypes {
  SECTION = 'section',
  MODULE = 'module_item'
}

export enum EModuleStatus {
  ACTIVE = 'active',
  CONCEPT = 'concept',
}

export enum EModuleTypes {
  CONTENT = 'content',
  QUIZ = 'quiz',
}

export enum ECourseTypes {
  ONBOARDING = 'onboarding',
  ACADEMY = 'academy',
}
