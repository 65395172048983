import React from 'react';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { omit } from 'lodash';

import Editor from './editor';
import type { MarkdownEditorOwnProps } from './editor';

type FieldProps = Omit<MarkdownEditorOwnProps, 'value' | 'onChange' | 'onFocus' | 'onBlur'>;

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export function MarkupEditorHookForm<T extends FieldValues>(props: FieldProps & UseControllerProps<T>) {
  const { field } = useController(props);
  return (
    <Editor
      {...props}
      {...field}
    />
  );
}

/**
 * Reverses the value before setting it and reverses it back before calling onChange.
 */
const ReduxFormField = ({ input, meta, ...props }: WrappedFieldProps & FieldProps) => {
  return (
    <Editor
      {...input}
      {...omit(props, ['meta', 'input'])}
    />
  );
};

const FormMarkup = (props: BaseFieldProps & MarkdownEditorOwnProps) => (
  <Field
    {...props}
    props={props}
    component={ReduxFormField}
  />
);

export default FormMarkup;
