import { combineReducers } from 'redux';
import * as R from 'ramda';
import {
  ORGANISATION_RECEIVE_MESSAGES,
  ORGANISATION_RECEIVE_MESSAGE,
  ORGANISATION_CREATE_MESSAGE,
  ORGANISATION_UPDATE_MESSAGE
} from '../../admin/actions';
import {
  SOCIAL_RECEIVE_FEED,
  SOCIAL_RECEIVE_SCHEDULED_MESSAGES,
  SOCIAL_RECEIVE_IMPORTANT_MESSAGES,
  SOCIAL_RECEIVE_UNREAD_MESSAGES,
  SOCIAL_POST_MESSAGE,
  SOCIAL_EDIT_MESSAGE,
  SOCIAL_RECEIVE_MESSAGE,
  SOCIAL_VOTE_POLL,
} from '../actions';

const isPoll = R.filter(R.propEq('object_type', 'poll'));
const addToStore = (acc, object) => R.assoc(object.source_id, {
  ...object.source,
  options: R.pluck('id', object.source.options),
  user_id: object.user_id,
}, acc);

const items = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
    case SOCIAL_RECEIVE_UNREAD_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGES:
      return R.pipe(
        R.pluck('children'),
        R.flatten,
        isPoll,
        R.reduce(addToStore, state),
      )(action.messages || action.items);
    case SOCIAL_RECEIVE_MESSAGE:
    case SOCIAL_POST_MESSAGE:
    case SOCIAL_EDIT_MESSAGE:
    case ORGANISATION_RECEIVE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
    case ORGANISATION_UPDATE_MESSAGE:
      if (!action.message) return state;
      // When editing a message it is possible to remove a poll.
      // When a message contains no poll, we should remove
      // the poll from state (if it exists).
      if (!R.pipe(isPoll)(action.message.children)?.length) {
        const pollIdToRemove = Object.keys(state).find((pollId) => state[pollId].message_id === action.message.source_id);
        if (pollIdToRemove) {
          const { [pollIdToRemove]: deletePoll, ...newState } = state;
          return newState;
        }
      }
      return R.pipe(isPoll, R.reduce(addToStore, state))(action.message.children);
    case SOCIAL_VOTE_POLL:
      return {
        ...state,
        [action.pollId]: {
          ...state[action.pollId],
          vote_result: action.optionIds,
          total_vote_count: action.vote ? state[action.pollId].total_vote_count + 1 : state[action.pollId].total_vote_count - 1,
        },
      };
    default: return state;
  }
};

const addOptionToStore = (acc, option) => R.assoc(option.id, option, acc);

const options = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
    case SOCIAL_RECEIVE_UNREAD_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGES:
      return R.pipe(
        R.pluck('children'),
        R.flatten,
        isPoll,
        R.map(R.path(['source', 'options'])),
        R.flatten,
        R.reduce(addOptionToStore, state),
      )(action.messages || action.items);
    case SOCIAL_RECEIVE_MESSAGE:
    case SOCIAL_POST_MESSAGE:
    case SOCIAL_EDIT_MESSAGE:
    case ORGANISATION_RECEIVE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
    case ORGANISATION_UPDATE_MESSAGE:
      if (!action.message) return state;

      return R.pipe(
        isPoll,
        R.map(R.path(['source', 'options'])),
        R.flatten,
        R.reduce(addOptionToStore, state),
      )(action.message.children);
    case SOCIAL_VOTE_POLL: {
      return {
        ...state,
        [action.optionId]: {
          ...state[action.optionId],
          vote_count: action.vote ? state[action.optionId].vote_count + 1 : state[action.optionId].vote_count - 1,
        }
      };
    }
    default: return state;
  }
};

const addToMessage = (acc, object) => R.assoc(object.parent_id, object.source_id, acc);

const messages = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
    case SOCIAL_RECEIVE_UNREAD_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGES:
      return R.pipe(
        R.pluck('children'),
        R.flatten,
        isPoll,
        R.reduce(addToMessage, state),
      )(action.messages || action.items);
    case SOCIAL_POST_MESSAGE:
    case SOCIAL_RECEIVE_MESSAGE:
    case SOCIAL_EDIT_MESSAGE:
    case ORGANISATION_RECEIVE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
    case ORGANISATION_UPDATE_MESSAGE:
      if (!action.message) return state;

      return R.pipe(isPoll, R.reduce(addToMessage, state))(action.message.children);
    default: return state;
  }
};

const findOptionById = R.curry((state, id) => state.social.polls.options[id]);

export const findByMessageId = R.curry((state, messageId) => {
  const poll = state.social.polls.items[state.social.polls.messages[messageId]];

  if (!poll) return null;

  return {
    ...poll,
    options: R.map(findOptionById(state), poll.options || []),
  };
});

export default combineReducers({ items, options, messages });
