import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/components/icon';
import { placesApi } from '@services/PlacesApi';
import { useDebounceOnChange } from '@hooks/useDebounceOnChange';

import type { PlaceOnMap } from '@common/types/objects';
import type { Nullable, Optional } from '@common/types/util-types';
import { Select, SelectOptionComponentProps } from '@common/components/form/inputs/select';

type LocationInputOption = {
  label: string;
  value: google.maps.places.AutocompletePrediction;
};

export const LocationInputOptionComponent = ({
  option,
  onSelect,
}: SelectOptionComponentProps<LocationInputOption>) => {
  const { value } = option;

  const formattedLabel = useMemo(() => {
    const {
      main_text,
      secondary_text,
      main_text_matched_substrings,
    } = value.structured_formatting;

    type TextNode = {
      start: number;
      end: number;
      isMatch: boolean;
    };

    const textMap = main_text_matched_substrings.reduce<TextNode[]>((acc, { offset, length }, index) => {
      if (index === 0 && offset > 0) {
        acc.push({ start: 0, end: offset, isMatch: false });
      }

      if (index > 0 && acc[index - 1].end < offset) {
        acc.push({
          start: acc[index - 1].end,
          end: offset,
          isMatch: false,
        });
      }

      acc.push({ start: offset, end: offset + length, isMatch: true });

      if (index + 1 === main_text_matched_substrings.length && (offset + length) !== main_text.length) {
        acc.push({ start: offset + length, end: main_text.length, isMatch: false });
      }

      return acc;
    }, []);

    const mainTextInTags = textMap.map((textNode) => {
      const substring = main_text.substring(textNode.start, textNode.end);

      if (textNode.isMatch) {
        return <b>{substring}</b>;
      }

      return substring;
    });

    return (
      <>
        <span className="LocationOption__MainText">{mainTextInTags}</span>
        &nbsp;
        <span className="LocationOption__SecondaryText">{secondary_text}</span>
      </>
    );
  }, [option]);

  return (
    <div className="Select-option LocationOption" onClick={() => onSelect(option)}>
      <Icon type="place" />
      {formattedLabel}
    </div>
  );
};

type LocationInputProps = {
  placeholder?: string;
  initialValue: Partial<Nullable<PlaceOnMap>> | void;
  onChange: (value: Optional<PlaceOnMap, 'geometry' | 'address'>) => Promise<void> | void;
};

export const LocationInput = ({ initialValue, placeholder, onChange }: LocationInputProps) => {
  const [options, setOptions] = useState<LocationInputOption[]>([]);
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValue && !selectedOption) setSelectedOption(initialValue);
  }, [initialValue]);

  const handleLoadOptions = async (query: string) => {
    const predictions = await placesApi.getAutocomplete(query);

    if (predictions) {
      const parsedToOptions = predictions.map((prediction) => ({
        label: prediction.description,
        value: prediction,
      }));

      setOptions(parsedToOptions);
    }

    if (isLoading) setIsLoading(false);
  };

  const [setSearchTerm] = useDebounceOnChange(
    initialValue ? initialValue.name ?? '' : '',
    handleLoadOptions,
    1000,
  );

  const handleSelect = async ({ label, value: { place_id } }: LocationInputOption) => {
    setIsLoading(false);

    const { geometry, address } = await placesApi.getPlaceLocation(place_id);
    const parsed: Parameters<typeof onChange>[0] = {
      address,
      geometry,
      place_id,
      name: label,
    };

    setSelectedOption(parsed);
    onChange(parsed);
  };

  const handleInputChange = async (query: string) => {
    if (selectedOption && query) setSelectedOption(undefined);
    if (query) setIsLoading(true);
    setSearchTerm(query);
  };

  return (
    <Select
      searchable
      isLoading={isLoading}
      value={selectedOption ? { label: selectedOption.name || selectedOption.address } : undefined}
      onBlur={() => setIsLoading(false)}
      placeholder={placeholder || t('common:search_location')}
      options={options}
      onInputChange={handleInputChange}
      // @ts-expect-error
      onChange={handleSelect}
      clearable={false}
      // @ts-expect-error
      OptionComponent={LocationInputOptionComponent}
    />
  );
};
