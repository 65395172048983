import React from 'react';
import * as R from 'ramda';
import i18n from '../../i18n';

type Value = string | object;

const defaultMapper = (value: string | number, i: number | string): JSX.Element => <span key={i}>{value}</span>;

const splitAtIndex = (index: number, items: string[]): [items: string[], hidden: string[]] => R.splitAt(index, items);

const mergeNames = (
  items: Value[],
  maxToDisplay = 2,
  key: string | null = null,
  mapper = defaultMapper,
  separator: string = i18n.t('common:utils_merged_names_text_and'),
  interTextSeparator = ', ',
) => {
  if (!items || items.length === 0) return [];

  // @ts-expect-error
  const getName = (value: object | string): string => (key && typeof value === 'object' ? value[key] : value);

  if (!Array.isArray(items) || items.length === 1) return [mapper(getName(items[0]), 0)];

  const names: string[] = R.map(getName, R.flatten([items]));
  const [namesToDisplay, hiddenCount] = maxToDisplay !== -1
    ? (() => {
      const [shown, hidden] = splitAtIndex(maxToDisplay, names);

      return [
        shown.map(mapper),
        R.length(hidden),
      ];
    })()
    : [names.map(mapper), 0];

  // @ts-expect-error
  const chunks = R.intersperse(interTextSeparator, namesToDisplay);

  if (hiddenCount) {
    const other = hiddenCount <= 1 ?
      i18n.t('common:utils_merged_names_text_other') :
      i18n.t('common:utils_merged_names_text_others');
    // @ts-expect-error
    chunks.push(` ${separator} `, mapper(hiddenCount, 'hidden'), ` ${other}`);
  } else {
    chunks[chunks.length - 2] = ` ${separator} `;
  }

  return chunks;
};

const stringMapper = (string: string) => string;

export function mergeNamesToString(items: Array<string | object>, maxToDisplay = 2, key: string | null = null) {
  // @ts-expect-error
  return mergeNames(items, maxToDisplay, key, stringMapper).join('');
}

export default mergeNames;
