import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as dateUtil from './common/utils/date-setup';
import Storage from './common/services/storage';

// const translationDa = require('../locale/da');
const translationDe = require('../locale/de');
const translationEn = require('../locale/en');
// const translationEs = require('../locale/es');
// const translationFi = require('../locale/fi');
const translationFr = require('../locale/fr');
// const translationHu = require('../locale/hu');
// const translationIt = require('../locale/it');
// const translationLt = require('../locale/lt');
const translationNl = require('../locale/nl');
// const translationNo = require('../locale/no');
// const translationPl = require('../locale/pl');
// const translationRo = require('../locale/ro');
// const translationSv = require('../locale/sv');

const setLanguage = (locale, isSetup = false) => {
  if (!isSetup) i18n.changeLanguage(locale);

  // Update locale for moment.js
  dateUtil.setup(locale, i18n);

  // Save locale in storage so it's quicker when initialising next time
  Storage.set('fa_locale', locale);

  // Send language to Intercom
  if (window.Intercom) window.Intercom('update', { language_override: locale });
};

export async function setup(locale) {
  await i18n
    .use(initReactI18next)
    .init({
      resources: {
        // da: translationDa,
        de: translationDe,
        en: translationEn,
        // es: translationEs,
        // fi: translationFi,
        fr: translationFr,
        // hu: translationHu,
        // it: translationIt,
        // lt: translationLt,
        nl: translationNl,
        // no: translationNo,
        // pl: translationPl,
        // ro: translationRo,
        // sv: translationSv,
      },
      compatibilityJSON: 'v2',
      interpolation: {
        escapeValue: false,
      },
      lng: locale,
      fallbackLng: 'en',
      returnEmptyString: false,
    });

  return setLanguage(locale, true);
}

export const changeLanguage = setLanguage;

export default i18n;
